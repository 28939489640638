import React from 'react';
import '../App.css';
import HeroSection from '../components/HeroSection';
import About from '../container/About/About';
import PortfolioGallery from '../container/Portfolio/PortfolioGallery';
import Listing from '../container/Listing/Listing';
import Footer from '../components/Footer';

function PortfolioPage() {
  return (
    <>
      <HeroSection />
      <PortfolioGallery />
      <Footer />
    </>
  );
}

export default PortfolioPage;
