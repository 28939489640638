import React from 'react';
import './Portfolio.css';
import { ButtonRoute } from '../../components/ButtonRoute';
import MenuItem from '../../components/MenuItem/MenuItem';

function PortfolioGallery() {
  return (
    <div className="app__gallery flex__center" id="portfolio">
      <div className="app__gallery-content">
        <h1 className="h__bright">Portfolio</h1>
        <p className="p__bright" style={{ color: '#AAAAAA', marginTop: '2rem' }}></p>
      </div>
      
      <div className="app__gallery-images">
        <MenuItem 
          img= '/images/6236.jpg'
          />
        <MenuItem 
        img= '/images/fremont44529.jpg'
        />
        <MenuItem 
        img= '/images/200.jpg'
        />
        <MenuItem 
        img= '/images/34601.jpg'
        />
        <MenuItem 
        img= '/images/18099.jpg'
        />
        <MenuItem 
        img= '/images/13490.jpg'
        />
        <MenuItem 
        img= '/images/120.jpg'
        />
        <MenuItem 
        img= '/images/2928.jpg'
        />
        <MenuItem 
        img= '/images/12113.jpg'
        />
        <MenuItem 
        img= '/images/6236-2.jpg'
        />
        <MenuItem 
        img= '/images/2034-2.jpg'
        />
        <MenuItem 
        img= '/images/13295.jpg'
        />
        <MenuItem 
        img= '/images/18261.jpg'
        />
        <MenuItem 
        img= '/images/512.jpg'
        />
        <MenuItem 
        img= '/images/705.jpg'
        />
        <MenuItem 
        img= '/images/608.jpg'
        />
        <MenuItem 
        img= '/images/1035.jpg'
        />
        <MenuItem 
        img= '/images/1045.jpg'
        />
        <MenuItem 
        img= '/images/2014.jpg'
        />
        <MenuItem 
        img= '/images/1435.jpg'
        />
      </div>
    </div>
  );
  
}

export default PortfolioGallery;
