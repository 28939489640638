import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import HubspotContactForm from "./HubspotContactForm";

function Footer() {
  return (
    <div className='footer-container' id="contacts">
      
      <section className='footer-subscription'>
        <h2 className='footer-subscription-heading'>
          
        </h2>
        <div className='footer-rows'>
          <div><HubspotContactForm /></div>
          <div>
            <p className='footer-subscription-text'>
          
            <br></br>
            
            <div class="info-wrap-contact_cell">
              <i class="fa-solid fa-phone"></i>
              <p class="contact_title">Phone</p>
              <a href="tel:(626) 822-1449">(626) 822-1449</a>
              
            </div>
            <div class="info-wrap-contact_cell">
              <i class="fa-solid fa-envelope"></i>
              <p class="contact_title">Email</p>
              <a href="mailto:Pollychengglobal@gmail.com">Pollychengglobal@gmail.com</a>
            </div>
            <div class="info-wrap-contact_cell">
              <i class="fa-brands fa-weixin"></i>
              <p class="contact_title">Wechat</p>
              <div class='footer-logo' id='contacts'>
                <img src='/images/Wechat.jpg'></img>
              </div>
            </div>
            </p>
          </div>
        
        </div>
      </section>
      <section class='social-media'>
        <div class='social-media-wrap'>
          
          <small class='website-rights'>Polly Cheng © 2022 <br></br> This is not intended as a solication if your property is currently listed with another broker. Information deemed reliable, but not guaranteed. 
Buyer to verify square footage (lot and structure) and all MLS information. Broker and broker's agents do not represent or guarantee accuracy of the square footage, bedroom/bathroom count, lot size or lot lines/ dimensions, permitted or unpermitted spaces, school boundary lines or eligibility, or other information concerning the conditions or features of the property. Buyer is advised to independently verify the accuracy of all information.</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
