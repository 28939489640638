import React from 'react';
import './MenuItem.css';

function MenuItem(props) {
  return (
    <>
      <div className='menuitem'>
          <img className='menuitem__img' src={props.img} alt="" />      
          <h1 className='card__city h__bright'>{props.title}</h1>
          <h2 className='card__city p__bright'>{props.price}</h2>
          <p className='card__description'>{props.description}</p>
      </div>
   </>
  );
}

export default MenuItem;
