import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: "100vw",
  height: "50vh"
};

const center = {
  lat: -3.745,
  lng: -38.523
};

function MyComponent() {
  return (
    <section classname="map" id="map">
    <LoadScript
      googleMapsApiKey="AIzaSyDnTW7elSVQJ8GXxjIuDlbmUGzGWqJqmvc">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
    </LoadScript>
    </section>
  )
}

export default React.memo(MyComponent)