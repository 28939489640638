import React from 'react';
import {Link} from 'react-router-dom'

function CardItem(props) {
  return (
    <>
      <div className='card'>
          <h1 className='h__dark' style={{textAlign: 'center'}}>{props.title}</h1>
          <p className='p__dark' style={{fontStyle: 'italic'}}>{props.description}</p>
      </div>
   </>
  );
}

export default CardItem;
