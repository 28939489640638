import React from 'react';
import './Portfolio.css';
import { ButtonRoute } from '../../components/ButtonRoute';
import MenuItem from '../../components/MenuItem/MenuItem';

function Portfolio() {
  return (
    <div className="app__gallery" id="portfolio">
      <div className="app__gallery-content">
        <h1 className="h__bright">Photos</h1>
        <p className="p__bright" style={{ color: '#AAAAAA', marginTop: '2rem' }}></p>
      </div>
      
      <div className="app__gallery-images">
        <MenuItem 
          
          img= '/images/6236.jpg'
          />
        <MenuItem 
        
        img= '/images/fremont44529.jpg'
        />
      </div>
    </div>
  );
  
}

export default Portfolio;
