import './App.css';
import React, { useEffect} from "react";
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import PortfolioPage from './pages/PortfolioPage';

export default function App() {

  useEffect(() => {
    document.title = "2034 E California | Currently Listed";  
  }, []);
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop>
        <Routes>
          <Route path='/' exact element={ <Home/>}></Route>
          <Route path='/portfolio' exact element={ <PortfolioPage/>}></Route>
        </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

