import React from 'react';
import '../App.css';
import HeroSection from '../components/HeroSection';
import About from '../container/About/About';
import Characteristic from '../container/About/Characteristic';
import Portfolio from '../container/Portfolio/Portfolio';
import Listing from '../container/Listing/Listing';
import Floorplan from '../container/Floorplan';
import Testimonial from '../container/Testimonial';
import Footer from '../components/Footer';
import Map from '../components/Map';

function Home() {
  return (
    <>
      <HeroSection />
      <Characteristic />
      <About />
      <Listing />
      <Floorplan />
      <Footer />
    </>
  );
}

export default Home;
