import React from 'react';
import './About.css';
import CardItem from '../../components/Card/CardItem';
import {Link} from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn';


const Characteristic = () => (
  <div className="app__about section__padding" id="characteristic">
    <FadeIn>
    <div className="app__about-highlight">
    <h1 className="h__dark">OFFERED AT $4,380,000</h1>
    <div className="app__charac__highlight highlightList space-between">
        <CardItem title="6" description="Bedrooms"/>
        <CardItem title="4.5" description="Bathrooms"/>
        <CardItem title="26262" description="Lot Size Sq Ft"/>
        <CardItem title="5411" description="Sq Ft"/>
        <CardItem title="1" description="Pool"/>
        <CardItem title="1" description="Guest House"/>
      </div>
    <h1 className="h__dark">Contact Listing Agent, Polly Cheng, to get More Details!!</h1>

      <section className="app__charac vertical__margin flex__row">
        <img src="/images/2034_tinified/MLS_33.jpg" />
      <div className="app__charac">
    
      <div>
        <p className="p__dark"><br></br><br></br>
          Main House     4495  SF <br></br>
          Guest House     916  SF <br></br>
          Laundry + Storage     152  SF <br></br>
          Garage     388 SF
        </p>
      </div>
          
          
    </div>  
      </section>
      
      <div className="app__features flex__center">
      <h1 className="h__dark">FEATURES</h1>
      <div className="highlightList_features">
        <p>
          
        </p>
        <CardItem description="Encredible Price Offering Base on Squared Footage • Double Entrance Flowthrough Driveway • Plenty Guest Parking • Close to the Renown Huntington Library • Open Floor Plan • Gated Property • Shopping Nearby • Great Schools • Expansive Windows • Entertainment Home  • Matured Fruit Trees"/>
      </div>
      <h1 className="h__dark title__padding  fade-in">First time on the market in 23 years!</h1>
      </div>
      


    </div>
    </FadeIn>
  </div>
);

export default Characteristic;
