import React, { useRef, useState }  from "react";
import SwiperCore, { EffectCoverflow, Pagination, Navigation, Thumbs, Zoom  } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Floorplan.css";

SwiperCore.use([EffectCoverflow, Pagination, Navigation, Thumbs, Zoom  ]);

const Floorplan = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
      <section>
      <div className="floorplan" id="floorplan">
        <h1>Double-click on the image to Zoom</h1>
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          zoom={true}
          modules={[Navigation, Thumbs, Zoom, Pagination]}
          className="mySwiper2"
          id="gallery"
        >
          <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/images/A.jpg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/images/B.jpg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/images/J.jpg" />
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
        <div className="swiper">
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={false}
          spaceBetween={10}
          slidesPerView={4}
          
          watchSlidesProgress={true}
          modules={[Navigation, Thumbs]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="/images/A.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/B.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/J.jpg" />
          </SwiperSlide>
        </Swiper>
      </div>
      </section>
    );
};

export default Floorplan;