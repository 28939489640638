import React from 'react';
import './About.css';
import CardItem from '../../components/Card/CardItem';
import {Link} from 'react-router-dom'
import YoutubeEmbed from "../../components/YoutubeEmbed";
import FadeIn from 'react-fade-in';

const About = () => (
  <div className="app__about section__padding" id="about">
    <FadeIn>
    <div className="app__about-highlight">
    <div className="flex__center">
    </div>
    <div className="app__about-content flex__center">
      {/* <div className="app__about-content_about">
      </div> */}
      <div className="app__about-content_history fade-in">
        <div className="center_align_text">
          
        </div>
        <h1 className="h__dark title__padding fade-in">OVERVIEW</h1>
        <div className="self_align">
          <img src="/images/2034_tinified/MLS_3.jpg" />
        </div>
        
        <section className="vertical__margin flex__row fade-in">
          <img src="/images/2034_tinified/MLS_11.jpg" />
          <p className="p__dark">
          The Magnificent California single family residence features 5 Bedrooms and 4 and a half Baths entertainment home is built for family. Located in the North of San Marino, one of the nicest places to live in California with world-class education provided by the San Marino Unified School District, this 4,445 SqFt house is ready for new home-owners.          </p>
        </section>

        <section className="vertical__margin flex__row fade-in">
          
          <p className="p__dark ">
          This genuine entertainment home includes a double, entrance/exit, flow-through driveway that offers enough of space for parking in order to accommodate all of your guests. There is space for 2 cars in the attached garage and 6 cars in the front yard. The garage is just next to the in-unit laundry. The house has newly installed carpets and wood tiles with central AC and heating system.           </p>
          <img src="/images/2034_tinified/MLS_1.jpg" />
        </section>
        <div className="self_align">
          <img src="/images/2034_tinified/MLS_13.jpg" />
        </div>
        <section className="vertical__margin flex__row">
          <img src="/images/2034_tinified/MLS_42.jpg" />
          <p className="p__dark">
          Featuring 5 Bedrooms and 4 and a half Baths and a guest house. 3 bedrooms are in the north/east wing, and 2 wonderful bedrooms for guest or kids rooms are conveniently located in the south/west wing for privacy.           </p>
        </section>
        <section className="vertical__margin flex__row">
          <p className="p__dark">
          Unique walls of glass create open, expansive views throughout this home. The attractive wood-floored, gracious Formal Living room opens to the roomy Family/Game room and Library, setting the mood for entertaining on any level.           </p>
          <img src="/images/2034_tinified/MLS_16.jpg" />
        </section>
        <div className="self_align">
          <img src="/images/2034_tinified/MLS_21.jpg" />
        </div>
        <section className="vertical__margin flex__row">
        <img src="/images/2034_tinified/MLS_24.jpg" />
          
          <p className="p__dark">
          A gorgeous custom-designed, cut glass window will complete this charming and cozy formal dining room. Stunning, contemporary gourmet kitchen, which was built with high-quality granite for the counters and flooring.</p>
        </section>
        <section className="vertical__margin flex__row">
          <p className="p__dark">
          The expansive 26,262 square foot lot features a "field of grass" backyard, matured fruit trees, and a welcoming private in-ground pool great for kids’ playgrounds and party hosting.            </p>
          <img src="/images/2034_tinified/MLS_51.jpg" />
        </section>
        <div className="self_align">
          <img src="/images/2034_tinified/MLS_64.jpg" />
        </div>
        <section className="vertical__margin flex__row">
          <p className="p__dark">
          Right next to the pool, there is a spacious, detached guest house with one bedroom and one bathroom, as well as kitchen cabinets for storage in the accessible buffet area and lovely wood-look flooring in the living room.           </p>
          <img src="/images/2034_tinified/MLS_71.jpg" />
        </section>
        <section className="vertical__margin flex__row">
          <img src="/images/2034_tinified/MLS_67.jpg" />
          <img src="/images/2034_tinified/MLS_70.jpg" />
        </section>
        <h2 className="p__dark">
        This home is imaginatively and uniquely designed. A one of a kind, custom tailored property for enjoying the "good life." If that is your desire . . . this "is" the home for you. 
        </h2>

      <div className="Youtube" id="video">
        <YoutubeEmbed embedId="U_f2LNL0Y6c" />
      </div>

        {/* Picturesque Cottage home offering an abundance of curb appeal. Enter into the cozy living room with hardwood floors, tray ceiling and charming fireplace with white painted brick. Remodeled kitchen is centrally located featuring eat-in dining with bay window and kitchen sink with view overlooking the whimsical enclosed side yard. Two bedrooms each boasting their own detailed features and a full bath in the middle of hallway. Finished garage includes laundry room, large storage closet and bonus room ideal for home office. All windows are newer and dual pane, great for insulation and sound. All of this is complete with an enclosed white picket fence front yard and large mature tree offering a canopy of shade creating the ideal space for outdoor play or simply relaxing with a freshly brewed iced tea in a fairytale setting. Close to restaurants and shops, Farmer’s Market, Gold Line and much more! */}

      </div>
    </div>
    </div>
    </FadeIn>
  </div>
);

export default About;
