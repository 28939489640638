import React, { useRef, useState }  from "react";
import SwiperCore, { EffectCoverflow, Pagination, Navigation, Thumbs, Autoplay  } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Listinghero.css";

SwiperCore.use([EffectCoverflow, Pagination, Navigation, Thumbs, Autoplay]);
// if you want to use array
const slide_img = [
  "https://swiperjs.com/demos/images/nature-1.jpg",
  "https://swiperjs.com/demos/images/nature-2.jpg",
  "https://swiperjs.com/demos/images/nature-3.jpg",
  "https://swiperjs.com/demos/images/nature-4.jpg",
  "https://swiperjs.com/demos/images/nature-5.jpg",
  "https://swiperjs.com/demos/images/nature-6.jpg",
  "https://swiperjs.com/demos/images/nature-7.jpg",
  "https://swiperjs.com/demos/images/nature-8.jpg",
  "https://swiperjs.com/demos/images/nature-9.jpg",
];

const Listinghero = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
      <section>
      <div className="listing">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation, Thumbs]}
          className="mySwiper2"
          id="gallery"
        >
          <SwiperSlide>
            <img src="/images/2034.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/20342.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/20343.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/20344.jpg" />
          </SwiperSlide>
        </Swiper>
      </div>
      </section>
    );
};

export default Listinghero;