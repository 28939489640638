import React, { useRef, useState }  from "react";
import SwiperCore, { EffectCoverflow, Pagination, Navigation, Thumbs, Autoplay, Zoom  } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Listing.css";
SwiperCore.use([EffectCoverflow, Pagination, Navigation, Thumbs, Autoplay, Zoom]);
// if you want to use array

const slide_img = [
  "/images/2034_tinified/MLS_1.jpg",
  "/images/2034_tinified/MLS_3.jpg",
  "/images/2034_tinified/MLS_7.jpg",
  "/images/2034_tinified/MLS_11.jpg",
  "/images/2034_tinified/MLS_12.jpg",
  "/images/2034_tinified/MLS_13.jpg",
  "/images/2034_tinified/MLS_16.jpg",
  "/images/2034_tinified/MLS_18.jpg",
  "/images/2034_tinified/MLS_21.jpg",
  "/images/2034_tinified/MLS_24.jpg",
  "/images/2034_tinified/MLS_27.jpg",
  "/images/2034_tinified/MLS_29.jpg",
  "/images/2034_tinified/MLS_31.jpg",
  "/images/2034_tinified/MLS_33.jpg",
  "/images/2034_tinified/MLS_34.jpg",
  "/images/2034_tinified/MLS_35.jpg",
  "/images/2034_tinified/MLS_36.jpg",
  "/images/2034_tinified/MLS_38.jpg",
  "/images/2034_tinified/MLS_39.jpg",
  "/images/2034_tinified/MLS_40.jpg",
  "/images/2034_tinified/MLS_42.jpg",
  "/images/2034_tinified/MLS_45.jpg",
  "/images/2034_tinified/MLS_46.jpg",
  "/images/2034_tinified/MLS_47.jpg",
  "/images/2034_tinified/MLS_50.jpg",
  "/images/2034_tinified/MLS_51.jpg",
  "/images/2034_tinified/MLS_53.jpg",
  "/images/2034_tinified/MLS_55.jpg",
  "/images/2034_tinified/MLS_61.jpg",
  "/images/2034_tinified/MLS_64.jpg",
  "/images/2034_tinified/MLS_67.jpg",
  "/images/2034_tinified/MLS_68.jpg",
  "/images/2034_tinified/MLS_69.jpg",
  "/images/2034_tinified/MLS_70.jpg",
  "/images/2034_tinified/MLS_74.jpg",
  "/images/2034_tinified/MLS_76.jpg",
  
];

const Listing = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
      <section className="listing-section">
      <div className="listing">
      <div className="floorplan">
        <h1>Double-click on the image to Zoom</h1>
        </div>
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          zoom={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation, Thumbs, Zoom]}
          className="mySwiper2"
          id="gallery"
        >
          {/* <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/images/2034_tinified/MLS_1.jpg" />
            </div>
          </SwiperSlide> */}
    
        {slide_img.map((img, i) => {
          return (
            <SwiperSlide key={i}>
              <div className="swiper-zoom-container">
                <img src={img} alt="" />
              </div>
            </SwiperSlide>
          );
        })}
          
        </Swiper>
      </div>
      <div className="swiper">
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          
          watchSlidesProgress={true}
          modules={[Navigation, Thumbs]}
          className="mySwiper"
        >
          {slide_img.map((img, i) => {
          return (
            <SwiperSlide key={i}>
                <img src={img} alt="" />
            </SwiperSlide>
          );
        })}
        </Swiper>
      </div>
      </section>
    );
};

export default Listing;