import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Link } from 'react-scroll';
import Listinghero from '../container/Listing/Listinghero';

function HeroSection(props) {
  return (
    <div className='hero-container'  id="nav">
        <video autoPlay loop muted>
          <source src='/videos/welcome2034.mp4' type="video/mp4" ></source>
        </video>
        
      <div className='hero-title fade-in'>
        <h2>2034 E California</h2>
        <h3>San Marino, CA </h3>
      </div>
      <div className='hero-btns fade-in'>
        <Link to='about' className='btn-mobile'>
          <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
          >
            GET STARTED
          </Button> 
        </Link>
        <Link to='contacts' className='btn-mobile'>
          <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            onClick={console.log('hey')}
          >
            CONTACTS <i className='far fa-play-circle' />
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default HeroSection;
